import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";

const initialState = {
  loading: false,
  errorMsg: "",
  toolsList: null,
  toolDetail: null,
  reviewList: null,
  exploreList: null,
  subscribedTools: null,
};

const marketPlaceSlice = createSlice({
  name: "marketPlace",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Get tool List -- */
      .addCase(getAllTools.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getAllTools.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.toolsList = action.payload;
      })
      .addCase(getAllTools.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get tool detail -- */
      .addCase(getToolDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getToolDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.toolDetail = action.payload;
      })
      .addCase(getToolDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get tool review -- */
      .addCase(getToolReview.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getToolReview.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.reviewList = action.payload;
      })
      .addCase(getToolReview.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Post tool review -- */
      .addCase(postToolReview.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(postToolReview.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        console.log("Post Review message", action.payload);
      })
      .addCase(postToolReview.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Explore all -- */
      .addCase(handleExploreAll.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(handleExploreAll.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";

        state.exploreList = action.payload;
      })
      .addCase(handleExploreAll.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- search -- */
      .addCase(handleSearch.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(handleSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.toolsList = action.payload;
      })
      .addCase(handleSearch.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Filter -- */
      .addCase(handleFilter.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(handleFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.toolsList = action.payload;
      })
      .addCase(handleFilter.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Subscription request -- */
      .addCase(sendSubscriptionRequest.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(sendSubscriptionRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(sendSubscriptionRequest.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Subscribed tools -- */
      .addCase(getSubscribedTools.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getSubscribedTools.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.subscribedTools = action.payload;
      })
      .addCase(getSubscribedTools.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* --- Get all Tools -- */
export const getAllTools = createAsyncThunk(
  "incident/getAllTools",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/capabilities/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Tool detail -- */
export const getToolDetail = createAsyncThunk(
  "incident/getToolDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/capabilities/${id}/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Get Tool reviews -- */
export const getToolReview = createAsyncThunk(
  "incident/getToolReview",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/capabilities/${id}/reviews/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- post reviews -- */
export const postToolReview = createAsyncThunk(
  "incident/postToolReview",
  async (reviewData, { rejectWithValue }) => {
    const { id, ...remainingData } = reviewData;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/capabilities/${id}/reviews/`,
        remainingData,
        {
          withCredentials: true,
        },
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Explore all -- */
export const handleExploreAll = createAsyncThunk(
  "incident/handleExploreAll",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/capabilities/?category_id=${id}`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Search -- */
export const handleSearch = createAsyncThunk(
  "incident/handleSearch",
  async (searchTerm, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/capabilities/?search=${searchTerm}`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Filter -- */
export const handleFilter = createAsyncThunk(
  "incident/handleFilter",
  async (filterOption, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/capabilities/?${filterOption}`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Subscription request -- */
export const sendSubscriptionRequest = createAsyncThunk(
  "incident/sendSubscriptionRequest",
  async (configurationData, { rejectWithValue }) => {
    const { id, ...remainingData } = configurationData;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/capabilities/${id}/send-request/`,
        remainingData,
        {
          withCredentials: true,
        },
      );
      const data = await response.data;
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Get subscribed Tools -- */
export const getSubscribedTools = createAsyncThunk(
  "incident/getSubscribedTools",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/my-choices/`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = marketPlaceSlice.actions;
export default marketPlaceSlice.reducer;

/* eslint-disable no-undef */
import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isCompaniesLoading: false,
  isConsultantLoading: false,
  isCertificationsLoading: false,
  isLogsLoading: false,
  errorMsg: "",
  companies: {},
  companyDetail: {},
  compliances: {},
  consultants: {},
  certifications: {},
  certificationDetail: {},
  logs: [],
  report: {},
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Get companies list -- */
      .addCase(getCompanies.pending, (state) => {
        state.isCompaniesLoading = true;
        state.errorMsg = "";
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.isCompaniesLoading = false;
        state.errorMsg = "";
        state.companies = action.payload;
      })
      .addCase(getCompanies.rejected, (state, action) => {
        state.isCompaniesLoading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Get company detail -- */
      .addCase(getCompanyDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getCompanyDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.companyDetail = action.payload;
      })
      .addCase(getCompanyDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- add company -- */
      .addCase(inviteCompany.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(inviteCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(inviteCompany.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- send invitation to company -- */
      .addCase(sendInvitation.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(sendInvitation.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(sendInvitation.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Update company detail -- */
      .addCase(updateCompanyDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateCompanyDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateCompanyDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Get compliance list -- */
      .addCase(getCompliances.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getCompliances.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.compliances = action.payload;
      })
      .addCase(getCompliances.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Get consultants -- */
      .addCase(getConsultants.pending, (state) => {
        state.isConsultantLoading = true;
        state.errorMsg = "";
      })
      .addCase(getConsultants.fulfilled, (state, action) => {
        state.isConsultantLoading = false;
        state.errorMsg = "";
        state.consultants = action.payload;
      })
      .addCase(getConsultants.rejected, (state, action) => {
        state.isConsultantLoading = false;
        state.errorMsg = action.payload.error;
      })
      /* --- Update consultant detail -- */
      .addCase(updateConsultantDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateConsultantDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateConsultantDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Create Report -- */
      .addCase(createReport.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(createReport.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.report = action.payload;
      })
      .addCase(createReport.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get certifications -- */
      .addCase(getCertifications.pending, (state) => {
        state.isCertificationsLoading = true;
        state.errorMsg = "";
      })
      .addCase(getCertifications.fulfilled, (state, action) => {
        state.isCertificationsLoading = false;
        state.errorMsg = "";
        state.certifications = action.payload;
      })
      .addCase(getCertifications.rejected, (state, action) => {
        state.isCertificationsLoading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get certification detail -- */
      .addCase(getCertificationDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getCertificationDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.certificationDetail = action.payload;
      })
      .addCase(getCertificationDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Update certification detail -- */
      .addCase(updateCertificationDetail.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(updateCertificationDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(updateCertificationDetail.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- create certification detail -- */
      .addCase(createCertification.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(createCertification.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(createCertification.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- create audit -- */
      .addCase(createAudit.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(createAudit.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(createAudit.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Upload certification report -- */
      .addCase(uploadReport.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(uploadReport.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
      })
      .addCase(uploadReport.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get certification logs-- */
      .addCase(getCertificationLogs.pending, (state) => {
        state.isLogsLoading = true;
        state.errorMsg = "";
      })
      .addCase(getCertificationLogs.fulfilled, (state, action) => {
        state.isLogsLoading = false;
        state.errorMsg = "";
        state.logs = action.payload;
      })
      .addCase(getCertificationLogs.rejected, (state, action) => {
        state.isLogsLoading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

export const getCompanies = createAsyncThunk(
  "companies/get_companies",
  async ({ params, nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey] || params[currentKey] === false) {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();

      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/consultation/companies/?limit=20&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getCompanyDetail = createAsyncThunk(
  "companies/get_company_detail",
  async ({ id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/company-detail/`;

    try {
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          Company: id,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const inviteCompany = createAsyncThunk(
  "companies/invite_company",
  async ({ data }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/invite-company/`;

    try {
      const response = await axios.post(url, data, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const sendInvitation = createAsyncThunk(
  "companies/send_invitation",
  async ({ id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/send-invitation/`;

    try {
      const response = await axios.post(url, null, {
        withCredentials: true,
        headers: {
          Company: id,
        },
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateCompanyDetail = createAsyncThunk(
  "companies/update_company_detail",
  async ({ data, id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/company-detail/`;

    try {
      const response = await axios.patch(url, data, {
        withCredentials: true,
        headers: {
          Company: id,
        },
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getCompliances = createAsyncThunk(
  "companies/get_compliances",
  async ({ params, nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey]) {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();

      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/consultation/compliances/?limit=20&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getConsultants = createAsyncThunk(
  "companies/get_consultants",
  async ({ params, nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey]) {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();

      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/consultation/consultants/?limit=20&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateConsultantDetail = createAsyncThunk(
  "companies/update_consultant_detail",
  async ({ data, id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/consultants/${id}/`;

    try {
      const response = await axios.patch(url, data, {
        withCredentials: true,
        company: id,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const createReport = createAsyncThunk(
  "companies/create_report",
  async ({ id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/create-report/`;

    try {
      const response = await axios.post(url, null, {
        withCredentials: true,
        responseType: "blob", // response is a Blob
        headers: {
          Company: id,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getCertifications = createAsyncThunk(
  "companies/get_certifications",
  async ({ params, nextUrl } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      let filteredParams = params;
      if (params) {
        filteredParams = Object.keys(params).reduce((result, currentKey) => {
          if (params[currentKey]) {
            result[currentKey] = params[currentKey];
          }
          return result;
        }, {});
      }
      const _params = new URLSearchParams(filteredParams).toString();

      const url =
        nextUrl ||
        `${process.env.REACT_APP_API_URL}api/v1/consultation/certifications/?limit=100&${_params}`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getCertificationDetail = createAsyncThunk(
  "companies/get_certification_detail",
  async ({ id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/certifications/${id}`;

    try {
      const response = await axios.get(url, {
        withCredentials: true,
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const createCertification = createAsyncThunk(
  "companies/create_certification",
  async ({ data }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/certifications/`;

    try {
      const response = await axios.post(url, data, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const createAudit = createAsyncThunk(
  "companies/create_audit",
  async ({ data }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/certifications/add-audit/`;

    try {
      const response = await axios.post(url, data, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const updateCertificationDetail = createAsyncThunk(
  "companies/update_certification_detail",
  async ({ data, id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/certifications/${id}/`;

    try {
      const response = await axios.patch(url, data, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const uploadReport = createAsyncThunk(
  "companies/upload_report",
  async ({ files, id }, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/audits/${id}/upload-report/`;

    try {
      const formData = new FormData();

      files.forEach((file, index) => {
        const blob = new Blob([file], { type: file.type });
        formData.append(`document`, blob, file.name);
        formData.append(`document_title`, file.name);
        if (file.description) {
          formData.append(`document_description`, file.description);
        }
      });

      const response = await axios.post(url, formData, {
        withCredentials: true,
      });

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const getCertificationLogs = createAsyncThunk(
  "companies/get_certification_logs",
  async ({ id } = {}, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";

      const url = `${process.env.REACT_APP_API_URL}api/v1/consultation/certifications/${id}/logs/`;
      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          "Accept-Language": language,
        },
      });

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = companiesSlice.actions;
export default companiesSlice.reducer;

import { Link } from "react-router-dom";
import {
  CYBERVIZE_IMPRINT_URL,
  CYBERVIZE_PRIVACY_POLICY_URL,
} from "../../utils/constant";

const Footer = () => {
  return (
    <>
      <footer className="flex h-12 md:bottom-0 w-full items-center justify-between py-2 px-8 bg-[#ffffff]">
        <div className="flex justify-center w-full text-md ">
          <Link className=" mr-14" to={CYBERVIZE_IMPRINT_URL} target="_blank">
            Imprint
          </Link>
          <Link to={CYBERVIZE_PRIVACY_POLICY_URL} target="_blank">
            Privacy policy
          </Link>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: false,
  dashboardData: null,
  errorMsg: "",
  incidents: {},
  tasks: {},
  risks: {},
};

const dashboardHomeSlice = createSlice({
  name: "dashboardHome",
  initialState,
  reducers: {
    resetState(state) {
      // Reset state to the initial state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder

      /* --- Payment -- */
      .addCase(fetchLevel.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(fetchLevel.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = "";
        state.dashboardData = action.payload;
      })
      .addCase(fetchLevel.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get Incident List -- */
      .addCase(getIncidents.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getIncidents.fulfilled, (state, action) => {
        state.loading = false;
        state.incidents = action.payload.results;
      })
      .addCase(getIncidents.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get task List -- */
      .addCase(getTasks.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.tasks = action.payload.results;
      })
      .addCase(getTasks.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      })

      /* --- Get risk List -- */
      .addCase(getRisks.pending, (state) => {
        state.loading = true;
        state.errorMsg = "";
      })
      .addCase(getRisks.fulfilled, (state, action) => {
        state.loading = false;
        state.risks = action.payload.results;
      })
      .addCase(getRisks.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload.error;
      });
  },
});

/* --- Dashboard home LEVEL -- */
export const fetchLevel = createAsyncThunk(
  "payment/fetchLevel",
  async (_, { rejectWithValue }) => {
    try {
      const language = localStorage.getItem("i18nextLng") || "de";
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/home/`,

        {
          withCredentials: true,
          headers: {
            "Accept-Language": language,
          },
        },
      );

      const responseData = await response.data;

      return responseData;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Get all incidents -- */
export const getIncidents = createAsyncThunk(
  "incident/getIncidents",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/incidents/?limit=500`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Get all tasks -- */
export const getTasks = createAsyncThunk(
  "tasks/getTasks",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/tasks/?limit=500`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

/* --- Get all risks -- */
export const getRisks = createAsyncThunk(
  "risks/getRisks",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/dashboard/asset-risk-levels/?limit=500`,
        {
          withCredentials: true,
        },
      );

      const data = await response.data;

      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ error: "An error occurred" });
      }
    }
  },
);

export const { resetState } = dashboardHomeSlice.actions;
export default dashboardHomeSlice.reducer;

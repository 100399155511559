import { useEffect, useState, lazy, Suspense } from "react";
import LocalContext from "./components/locale-context/LocalContext";
import i18n from "./i18n";
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userCompleteDetails } from "./features/login/authSlice";
import Loader from "./common/loader/Loader";
import Footer from "./components/footer/Footer";

import theme from "./themeConfig";
import { setAppError, setAppInfo } from "./store/commonSlice";
import SnackbarAlert from "./common/snackbaralert/SnackbarAlert";
import AppErrorBoundary from "./AppErrorBoundary";
import routes from "./utils/routes";
import WithoutAuthRedirect from "./hocs/WithoutAuthRedirect";

const Navbar = lazy(() => import("./components/navbar/Navbar"));

const LandingPage = lazy(() => import("./pages/landing-page/LandingPage"));

function App() {
  const [locale, setLocale] = useState(i18n.language);
  const appLang = useSelector((state) => state.setting.language);
  const location = useLocation();
  const dispatch = useDispatch();
  // snackbar alert messages
  const { errorMessage, infoMessage } = useSelector((state) => state.common);

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e);
  };

  useEffect(() => {
    const updateLocale = (lang) => {
      setLocale(lang);
    };
    i18n.on("languageChanged", updateLocale);
    return () => {
      i18n.off("languageChanged", updateLocale);
    };
  }, []);

  useEffect(() => {
    dispatch(userCompleteDetails());
  }, [dispatch]);

  const handleCloseSnackbar = () => {
    dispatch(setAppError(null));
    dispatch(setAppInfo(null));
  };

  const isDashBoard = location.pathname.startsWith("/dashboard");

  useEffect(() => {
    handleLanguageChange(appLang);
  }, [appLang]);

  return (
    <AppErrorBoundary>
      <ThemeProvider theme={theme}>
        <section className="sans flex flex-col h-screen bg-[#FFFFFF]">
          <Suspense fallback={<Loader />}>
            <main className="flex-grow bg-[#FFFFFF]">
              <LocalContext.Provider value={{ locale, setLocale }}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <WithoutAuthRedirect
                        element={
                          <LandingPage
                            locale={locale}
                            onHandleLanguageChange={handleLanguageChange}
                          />
                        }
                      />
                    }
                  />
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={
                        <>
                          {route.navbar && (
                            <Navbar
                              locale={locale}
                              onHandleLanguageChange={handleLanguageChange}
                            />
                          )}
                          {route.element}
                        </>
                      }
                    />
                  ))}
                </Routes>
              </LocalContext.Provider>
            </main>

            {!isDashBoard && <Footer />}
          </Suspense>
        </section>
        <SnackbarAlert
          open={!!errorMessage || !!infoMessage}
          handleClose={handleCloseSnackbar}
          message={errorMessage || infoMessage}
          severity={errorMessage ? "error" : "success"}
        />
      </ThemeProvider>
    </AppErrorBoundary>
  );
}

export default App;
